import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Col, Table, Modal, Row, Form } from "react-bootstrap";
import { Highlight } from "react-instantsearch";
import "instantsearch.css/themes/satellite.css";
import { Hits, InstantSearch, SearchBox, Configure } from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";
import axios from "axios";
import { showLoadingModal,hideLoadingModal } from "../logic/Utils";
import Swal from "sweetalert2";
const searchClient = algoliasearch("1QSB9J0W2M", "a323d204530aa5c9d75b1c977cd1dc29");

export const Hit = ({ hit, onClick }) => {
    const handleClick = () => {
      // Custom onClick action
      onClick(hit);
    };
  
    return (
      <article onClick={handleClick}>
        <div className="hit-Description">
          <Highlight attribute="Description" hit={hit} />
        </div>
        <div className="hit-Code">
          <Highlight attribute="Code" hit={hit} />
        </div>
      </article>
    );
  };
  const TariffMatchModal = ({ tariffMatch, closeModal, onSuccess }) => {

  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );
  const [category, setCategory] = useState("");
  function checkPermission(userDetails) {
   
    const result = (
      (userDetails.role == "MANAGER") ||
      userDetails.department == "MEDICAL SERVICE" ||
      userDetails.department == "ICT"
  );

    return result;
}

  const [newTariffAlias, setNewTariffAlias] = useState({
    suggestedTariffDescription: "",
    suggestedTariffCode: "",
    providerTariffCode: "",
    providerTariffDescription: tariffMatch.providerTariffDescription,
  });


  const handleSave = async () => {
    
   
    newTariffAlias.providerTariffDescription = tariffMatch.providerTariffDescription;
    console.log(newTariffAlias);
    // Check if user has permission to save
    if (!checkPermission(userDetails[0])) {
      Swal.fire({
        title: "Permission Denied",
        text: "You do not have permission to save tariffs",
        icon: "error",
      });
      return;
    }
    // Display loading modal while processing
    showLoadingModal();

    
  
    try {
      const name = userDetails.firstName;
      const response = await axios.post(
        `https://metrotariff-872093905250.us-central1.run.app/tariff/alias/${name}`,
        newTariffAlias,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      // If the request is successful, hide loading modal and show success message
      if (response.status === 200) {
        hideLoadingModal();
        onSuccess(tariffMatch);
        Swal.fire({
          title: "Tariff Saved",
          text: " You can re-run the analysis to see the changes, or you can continue to review other results.",
          icon: "success",
        }).then(() => {
          closeModal();
        });
      }
    } catch (error) {
      // If an error occurs, log it and display error message
      console.error("Upload error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
     
    }
  };
  


 
  const handleHitClick = (hit) => {

  
    // Set form fields with hit data
    setNewTariffAlias({
      suggestedTariffCode: hit.Code,
      suggestedTariffDescription: hit.Description,
    });
    setCategory(hit['Service Type']);
  };
  useEffect(() => {
    Prism.highlightAll();
    //authorize entry
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update the state with the new value for the specific property
    setNewTariffAlias({ ...newTariffAlias, [name]: value });
  };
  const [lgShow, setLgShow] = useState(true);

  return (
    <React.Fragment>
      <div className="main main-docs">
        <Modal show={lgShow} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Tariff Match </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
              <Row>
              <Col md={4}>
              <Table striped bordered hover>
              
                <tbody>
                
                  <tr>
                    <td>Provider Description</td>
                    <td> <strong>{tariffMatch.providerTariffDescription}</strong> </td>
                  </tr>
                </tbody>
              </Table>
              </Col>
                <Col md={8}>
                <h2>Metro Tariff Match</h2>
                <Form>
                  <Form.Group as={Row} controlId="formTariffCode">
                    <Form.Label column sm={3}>
                      Tariff Code:
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Tariff Code"
                        name="tariffCode"
                        value={newTariffAlias.suggestedTariffCode} // Bind the value from state to the form control
                        onChange={handleChange} // Call the handleChange function on input change
    
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formDescription">
                    <Form.Label column sm={3}>
                      Description:
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        name="description"

                        value={newTariffAlias.suggestedTariffDescription} // Bind the value from state to the form control
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formCategory">
                    <Form.Label column sm={3}>
                      Category:
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Category"
                        name="category"
                        value={category} // Bind the value from state to the form control
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Form>

                <Button
              style={{ marginTop: "20px" }}
              variant="success"
              onClick={() => handleSave()}
            >
              Save 
            </Button>
                </Col>

              </Row>
                <hr />
                <h5>Search Results</h5>
                <InstantSearch searchClient={searchClient} indexName="data">
        
                  <Configure hitsPerPage={10} />
                  <div className="ais-InstantSearch">
                    <SearchBox  />
                    <Hits hitComponent={({ hit }) => <Hit hit={hit} onClick={handleHitClick} />} />

                  </div>
                </InstantSearch>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
export default TariffMatchModal;
