import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Card,
  Container,
  Nav,
  Table,
  Form,
  Button,
  Tab,
  Spinner,
} from "react-bootstrap";
import Footer from "../layouts/Footer";
import Swal from "sweetalert2";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { TaskService } from "../service/TaskService";
import axios from "axios";
import TariffResult from "../components/TariffResult";

import { Link } from "react-router-dom";
import MetroTariff from "../components/MetroTariff";
import ClaimsResult from "../components/ClaimsResult";

export default function ClaimsTracker() {


  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [data, setData] = React.useState([]);
  const [dataClaims, setDataClaims] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [isMetroProcessed, setMetroIsProcessed] = React.useState(false);

  const [file, setFile] = React.useState(null);

  useEffect(() => {
    Prism.highlightAll();

  }, []);



  const handleUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file); // Replace with your actual file object

    try {
        const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://metroflowbackend-872093905250.us-central1.run.app/claims/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response, e.g., show a success message
      console.log("Upload response:", response.data);

      
      setDataClaims(response.data.result);
      const transformedData = response.data.result.map((item) => ({
        batchNumber: item.batchNumber,
        claimsType: item.claimsType,
        providerName: item.providerName,
        providerBilledAmount: item.providerBilledAmount,
        year: item.year,
        month: item.month,
        dateAdded: item.dateAdded,
        levelDescription: item.levelDescription,
    }));
    
      setData(transformedData);
      setIsProcessed(true);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };


  const handleSendEMails = async () => {
    setIsLoading(true);

    try {
        const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://metroflowbackend-872093905250.us-central1.run.app/claims/send-reminders",dataClaims,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response, e.g., show a success message
      if(response.status===200){
        setIsLoading(false);
        Swal.fire(
          "Successful",
          "Emails have been sent",
          "success"
        );
      }
    }
     catch (error) {
      setIsLoading(false);
      Swal.fire("Action failed", error.response.data.result, "error");
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Container>
          <label className="main-title-label">Claims Tracker</label>
          {/* <h2 className="main-title"></h2> */}

          <hr className="main-separator" />

          <Tab.Container defaultActiveKey={"uploadTab"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="uploadTab">
                  Upload Claims Report
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="uploadTab">
                {
                  <Card className="card-post mt-4">
                  <Card.Header>
                    <Card.Title>Upload Claims</Card.Title>
                    <Link to="" className="link-more">
                      <i className="ri-more-2-fill"></i>
                    </Link>
                  </Card.Header>
                  <Card.Body>
                    <div className="mb-3">

                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                          Upload claims report  (XLSX files only)
                        </Form.Label>
                        <Form.Control
                          onChange={(event) => setFile(event.target.files[0])}
                          type="file"
                        />
                      </Form.Group>

                      {!isLoading && (
                        <Button variant="primary" onClick={handleUpload}>
                          Submit
                        </Button>
                      )}
                      {isLoading && (
                        <Button className="btn-sign">
                          Processing...
                          <span />
                          <Spinner size="sm" animation="border" />
                        </Button>
                      )}
                    </div>

                    <br />
                    <br />
                    <br />

                    {isProcessed && <ClaimsResult data={data} />}
                  </Card.Body>
                  <Card.Footer>

                  {!isLoading && (
                        <Button variant="primary" onClick={handleSendEMails}>
                          Send Reminders
                        </Button>
                      )}
                      {isLoading && (
                        <Button className="btn-sign">
                          Processing...
                          <span />
                          <Spinner size="sm" animation="border" />
                        </Button>
                      )}

                  </Card.Footer>
                </Card> }
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
}
