import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Card,
  Container,
  Nav,
  Modal,
  Form,
  Button,
  Tab,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";

import Swal from "sweetalert2";
import { showLoadingModal, hideLoadingModal } from "../../logic/Utils";


import axios from "axios";

import { Link } from "react-router-dom";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const PaymentAdviceGrid = (props) => {

  const [selectedProviderMatch, setSelectedProviderMatch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [rows, setRows] = useState(props.data);

  const handleView = (row) => {

   
 
     
     setShowModal(true);
      setViewMode(true);
      setSelectedProviderMatch(row);
    
   };
   const handleClose = () => {
    // Implement your edit logic here
    setShowModal(false);
    setSelectedProviderMatch(null);
  };


 
  const paymentColumns = [
    {
      field: "providerName",
      headerName: "Provider Name",
      width: 350,
    },
    {
      field: "providerKey",
      headerName: "Provider Key",
      width: 250,
    },
    {
      field: "paymentDescription",
      headerName: "Payment Description",
      width: 500,
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      width: 150,
    },
    {
      field: "amountInFigure",
      headerName: "Amount (₦)",
      width: 150,
    },
    {
      field: "amountInWords",
      headerName: "Amount (In Words)",
      width: 350,
    },
   
    {
      field: "bankName",
      headerName: "Bank Name",
      width: 200,
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      width: 200,
    },
    {
      field: "attachmentLink",
      headerName: "Payment Advice PDF",
      width: 150,
      renderCell: (params) => (
        <>
          <a href={params.row.attachmentLink} target="_blank" rel="noopener noreferrer">
            <Button variant="primary" style={{ marginRight: "10px" }}>
              View PDF
            </Button>
          </a>
        </>
      ),
    }
    ,
   
  ];
  


  const handleNotification = async () => {
    // Display loading modal while processing
    showLoadingModal();
  
    try {
      // Send POST request to add alias entries
      const response = await axios.post(
        "https://metroflowbackend-872093905250.us-central1.run.app/medicalservices/submit",
        rows,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json"
        },
        }
      );
  
      // If the request is successful, hide loading modal and show success message
      if (response.data.message === "success") {
        hideLoadingModal();
       
        Swal.fire({
          title: "Success",
          text: " Notifications was sent",
          icon: "success",
        });
      }
    } catch (error) {
      // If an error occurs, log it and display error message
      console.error("Upload error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
     
    }
  };
  

  return (
    <React.Fragment>
      {" "}
   
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={paymentColumns}
        
          pageSize={20}
          checkboxSelection
          // onRowSelectionModelChange={(newSelectionModel) => {
          //   setRowSelectionModel(newSelectionModel);

          //   const selectedTariffs = newSelectionModel.map((selectedId) =>
          //     rows.find((row) => row.id === selectedId)
          //   );

          //   setAliasEntries(selectedTariffs);
          // }}
          rowSelectionModel={rowSelectionModel}
          sx={{
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: 'rgba(120,144,156,0.5)', // Custom header background color
      fontSize: '1rem', // Custom header font size
    },
    '& .MuiDataGrid-row': {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(235,237,240,1)', // Lighter for odd rows
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'rgba(215,217,220,1)', // Slightly darker for even rows
      },
    },
  }}
          search
        />
      </div>

      <Row style={{ marginTop: "20px" }} className="text-center mb-4">
        <Col>
          <Button  variant="primary" onClick={handleNotification}>
            Send Payment Advice Notifications
          </Button>
        </Col>
        <Col>
          {" "}
         
        </Col>
      </Row>
      {(selectedProviderMatch && viewMode)&& (
        <div>
        <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Matched Tariff</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Label>Provider Tariff Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Tariff Code"
                    value={selectedProviderMatch.providerTariffDescription}
                    disabled
                    style={{ marginBottom: "20px" }}
                  />

                  <Form.Label>Suggested Tariff Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Tariff Description"
                    value={selectedProviderMatch.suggestedTariffDescription}
                    disabled
                    style={{ marginBottom: "20px" }}
                  />

                  <Form.Label>Matching Score</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Tariff Category"
                    value={selectedProviderMatch.matchingScore}
                    disabled
                    style={{ marginBottom: "20px" }}
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
        
       </Modal>
        </div>
      )}
    </React.Fragment>
  );
};





export default function ProviderManager() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [providerData, setProviderData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [metroFile, setMetroFile] = React.useState(null);
  const [metrotariffData, setMetrotariffData] = React.useState([]);
  const [isMetroTariffsLoaded, setIsMetroTariffsLoaded] = React.useState(false);
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [showTariffModal, setShowTariffModal] = useState(false);
  const userDetails = useState(
    JSON.parse(localStorage.getItem("userDetails")) || {}
  );
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  


  const getMetroPaymentAdvice = async () => {
    setProviderData([]);
    setIsMetroTariffsLoaded(false);
    showLoadingModal();

    try {
      const response = await axios.get(
        "https://metroflowbackend-872093905250.us-central1.run.app/medicalservices/paymentadvice",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json"
        },
        }
      );


      let id = 1;
      const transformedData = response.data.result.map((item) => ({
        bankName: item.bankName,
        accountNumber: item.accountNumber,
        paymentDate: item.paymentDate,
        providerKey: item.providerKey,
        providerName: item.providerName,
        amountInFigure: item.amountInFigure,
        amountInWords: item.amountInWords,
        attachmentLink: item.attachmentLink,
        id: id++,
        paymentDescription: item.paymentDescription,
      }));
     

      Swal.fire({
        title: "Payment Advice Extracted",
        text: "",
        icon: "success",
      });

      hideLoadingModal();
      setProviderData(transformedData);
      setIsProcessed(true);
    } catch (error) {
      hideLoadingModal();

      console.error("Upload error:", error);
    }
  };

  const handleClose = () => {
    setShowTariffModal(false);
    setSelectedTariff(null);
  };

  const handleUpload = async () => {
    setProviderData([]);
    setIsProcessed(false);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file); // Replace with your actual file object

    try {
      const response = await axios.post(
        "https://metroflowbackend-872093905250.us-central1.run.app/medicalservices/payment-advice",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data"
        },
        }
      );
 let id = 1;
      const transformedData = response.data.result.map((item) => ({
        bankName: item.bankName,
        accountNumber: item.accountNumber,
        paymentDate: item.paymentDate,
        providerName: item.providerName,
        providerKey: item.providerKey,
        amountInFigure: item.amountInFigure,
        amountInWords: item.amountInWords,
        attachmentLink: item.attachmentLink,
        id: id++,
        paymentDescription: item.paymentDescription,
      }));
      setProviderData(transformedData);
      setIsProcessed(true);

      Swal.fire({
        title: "Payment Advice Extracted",
        text: "You can review the results, and send notifications",
        icon: "success",
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
    }
  };


  const handleMetroUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", metroFile);

    try {
      const response = await axios.post(
        "https:localhost:8080/upload/update",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsProcessed(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error("Upload error:", error);
    }
  };

  const handleAddTariff = (tariffDto) => {
    Swal.fire({
      title: "Unauthorized",
      text: "Admins Only",
      icon: "error",
    });

    return;

  
  };

  const createTariff = () => {
    setShowTariffModal(true);
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Container>
          <label className="main-title-label">PROVIDER MANAGER</label>

          <Tab.Container defaultActiveKey={"providerTab"} className="mt-5">
            <Nav className="nav-line ">
            <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="providerTab"
                >
                  Provider List
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="uploadTab">
                  Payment Advice
                </Nav.Link>
              </Nav.Item>
            
            </Nav>
            <Tab.Content>
            <Tab.Pane eventKey="providerTab">
                {
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Upload MetroHealth Provider List </Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Upload provider sheet (Excel)</Form.Label>
                          <Form.Control
                            onChange={(event) =>
                              setMetroFile(event.target.files[0])
                            }
                            type="file"
                          />
                        </Form.Group>

                        {!isLoading && (
                          <Button style={{ marginRight: "20px" }} variant="primary" onClick={handleMetroUpload}>
                            Upload
                          </Button>
                        )}
                        {isLoading && (
                          <Button className="btn-sign">
                            Processing...
                            <span />
                            <Spinner size="sm" animation="border" />
                          </Button>
                        )}
                        <Button  style={{ marginRight: "20px" }} variant="success" onClick={createTariff}>
                          Add Provider Record
                        </Button>
                        <Button style={{ marginRight: "20px" }}variant="secondary" onClick={createTariff}>
                          Fetch Provider List
                        </Button>
                      </div>

                  
                      <br />

                      {isMetroTariffsLoaded && (
                        <PaymentAdviceGrid data={metrotariffData} />
                      )}

                      {showTariffModal && (
                        <Modal show={showTariffModal} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Edit Provider</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Label> Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Tariff Code"
                                value={
                                  selectedTariff
                                    ? selectedTariff.tariffCode
                                    : ""
                                }
                                onChange={(e) =>
                                  setSelectedTariff({
                                    ...selectedTariff,
                                    tariffCode: e.target.value,
                                  })
                                }
                                style={{ marginBottom: "20px" }}
                              />

                              <Form.Label>Profssional contact</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Professional contact"
                                value={
                                  selectedTariff
                                    ? selectedTariff.tariffDescription
                                    : ""
                                }
                                onChange={(e) =>
                                  setSelectedTariff({
                                    ...selectedTariff,
                                    tariffDescription: e.target.value,
                                  })
                                }
                                style={{ marginBottom: "20px" }}
                              />

                              
                              <Button
                                variant="primary"
                                onClick={() => handleAddTariff(selectedTariff)}
                              >
                                Submit
                              </Button>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                }
              </Tab.Pane>
              <Tab.Pane eventKey="uploadTab">
                {
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Upload Payment Advice List</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>
                            Upload file (Excel)
                          </Form.Label>
                          <Form.Control
                            onChange={(event) => setFile(event.target.files[0])}
                            type="file"
                          />
                        </Form.Group>

                        {!isLoading && (
                          <Button style={{ marginRight: "20px" }} variant="primary" onClick={handleUpload}>
                            Submit
                          </Button>

                        
                        )}
                        {isLoading && (
                          <Button style={{ marginRight: "20px" }}className="btn-sign">
                            Processing...
                            <span />
                            <Spinner size="sm" animation="border" />
                          </Button>
                        )}
                        <Button variant="secondary" onClick={getMetroPaymentAdvice}>
                          Fetch Payment Advice List
                        </Button>
                      
                      </div>

                      <br />
                    

                      {isProcessed && (
                        <PaymentAdviceGrid
                          data={providerData}
                        />
                      )}
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                }
              </Tab.Pane>

            
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </React.Fragment>
  );
}
