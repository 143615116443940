
const dashboardMenu = [
  {
    "label": "Overview",
    "link": "/dashboard/overview",
    "icon": "ri-pie-chart-2-line"
  },
  {
    "label": "My Task Manager",
    "icon": "ri-checkbox-multiple-line",

    "submenu": [

      {
        "label": "Newly Assigned Tasks",
        "link": "/tasks/new",
        "icon": "ri-coin-line"
      },
      {
        "label": "Ongoing Tasks",
        "link": "/tasks/ongoing",
        "icon": "ri-coin-line"
      },
      {
        "label": "Tasks Marked For Extension",
        "link": "/tasks/extensions",
        "icon": "ri-coin-line"
      },
      {
        "label": "Completed Tasks",
        "link": "/tasks/completed",
        "icon": "ri-coin-line"
      }
    ]

  },

  {
    "label": "My WorkPlans",
    "link": "/workplans",
    "icon": "ri-bar-chart-2-line"
  },

  {
    "label": "Create WorkPlan",
    "link": "/create/workplan",
    "icon": "ri-bar-chart-2-line"
  },

];
const teamReportsMenu = [

  {
    "label": "My Team Reports",
    "link": "/dashboard/reports",
    "icon": "ri-hard-drive-2-line"
  },

];
const allReportsMenu = [

  {
    "label": "Underwriting Team",
    "link": "/dashboard/Underwriting",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "Sales Team",
    "link": "/dashboard/Sales",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "Medical Services",
    "link": "/dashboard/medical",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "Client Services Team",
    "link": "/dashboard/client",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "Legal Team",
    "link": "/dashboard/legal",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "ICT Team",
    "link": "/dashboard/ict",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "Operations Team",
    "link": "/dashboard/operations",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "HR/Admin Team",
    "link": "/dashboard/hr",
    "icon": "ri-hard-drive-2-line"
  }
];
const lineManagerMenu = [

  {
    "label": "Create Task",
    "link": "/create/task",
    "icon": "ri-hard-drive-2-line"
  },


  {
    "label": "Create Work Plan For Team",
    "link": "/team/create/workplan",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "My Team's Tasks",
    "link": "/my-team",
    "icon": "ri-coin-line",
    "submenu": [
      {
        "label": "Newly Assigned Tasks",
        "link": "/tasks/team/new",
        "icon": "ri-coin-line"
      },
      {
        "label": "OngoingTasks",
        "link": "/tasks/team/ongoing",
        "icon": "ri-coin-line"
      },
      {
        "label": "Tasks Marked For Extension",
        "link": "/tasks/team/extensions",
        "icon": "ri-coin-line"
      },
      {
        "label": "Completed Tasks",
        "link": "/tasks/team/completed",
        "icon": "ri-coin-line"
      },
    ],

  },
  {
    "label": "My Team's WorkPlans",
    "link": "/team/workplans",
    "icon": "ri-bar-chart-2-line"
  },
  {
    "label": "Team Members",
    "link": "/my-team",
    "icon": "ri-coin-line"
  },

];
const hrMenu = [
  {
    "label": "Create Task",
    "link": "/create/task",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": "My Team",
    "link": "/my-team",
    "icon": "ri-coin-line",
    "submenu": [
      {
        "label": "Newly Assigned Tasks",
        "link": "/tasks/team/new",
        "icon": "ri-coin-line"
      },
      {
        "label": "OngoingTasks",
        "link": "/tasks/team/ongoing",
        "icon": "ri-coin-line"
      },
      {
        "label": "Tasks Marked For Extension",
        "link": "/tasks/team/extensions",
        "icon": "ri-coin-line"
      },
      {
        "label": "Completed Tasks",
        "link": "/tasks/team/completed",
        "icon": "ri-coin-line"
      },
      {
        "label": "Team Members",
        "link": "/my-team",
        "icon": "ri-coin-line"
      },
      {
        "label": "All Teams",
        "link": "/teams",
        "icon": "ri-hard-drive-2-line"
      },
    ],
  },
  {
    "label": " Invite Employee",
    "link": "/invite/employee",
    "icon": "ri-hard-drive-2-line"
  },
  {
    "label": " Upload KPI",
    "link": "/employee/kpi",
    "icon": "ri-hard-drive-2-line"
  },

];
const pagesMenu = [
  {
    "label": "My Profile",
    "icon": "ri-account-circle-line",
    "link": "/employee/profile",
  }
];

const appsMenu = [
  {
    "label": "Sales",
    "icon": "ri-account-circle-line",
    "link": "/pages/profile",
    "submenu": [
      {
        "label": "Sales Analytics",
        "link": "/analytics/sales",
         "icon": "ri-hard-drive-2-line"
      },
       {
        "label": "Channels",
        "link": "/analytics/leads",
         "icon": "ri-hard-drive-2-line"
      },
      {
        "label": "Sales Records",
        "link": "/analytics/sales-records",
         "icon": "ri-hard-drive-2-line"
      },
    ]
  },
  {
    "label": "Medical Services",
    "icon": "ri-account-circle-line",
    "link": "/pages/profile",
    "submenu": [
      {
        "label": "Tariff Manager",
    "icon": "ri-account-circle-line",
    "link": "/tariff-manager",
      },
       {
        "label": "Provider Manager",
        "link": "/medical-services/providers",
         "icon": "ri-hard-drive-2-line"
      },
      {
        "label": "WTTS",
        "link": "/medical-services/activity",
         "icon": "ri-hard-drive-2-line"
      },
    ]
  },
  {
    "label": "Claims Tracker",
    "icon": "ri-account-circle-line",
    "link": "/claims-tracker",
  },
  {
    "label": "Invoice Tracker",
    "icon": "ri-account-circle-line",
    "link": "/invoice-tracker",
  },

  {
    "label": "Finance Reports",
    "icon": "ri-account-circle-line",
    "link": "/report-manager",
  },
  {
    "label": "Sales",
    "icon": "ri-account-circle-line",
    "link": "/pages/profile",
    "submenu": [
      {
        "label": "Sales Analytics",
        "link": "/analytics/sales",
         "icon": "ri-hard-drive-2-line"
      },
       {
        "label": "Channels",
        "link": "/analytics/leads",
         "icon": "ri-hard-drive-2-line"
      },
      {
        "label": "Sales Records",
        "link": "/analytics/sales-records",
         "icon": "ri-hard-drive-2-line"
      },
    ]
  },

];



export { dashboardMenu, hrMenu, pagesMenu, lineManagerMenu, teamReportsMenu , allReportsMenu,appsMenu};