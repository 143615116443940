import React, {useEffect, useState, useRef, useCallback, useMemo} from "react";
import Prism from "prismjs";
import {
    Card,
    Container,
    Nav,
    Modal,
    Form,
    Button,
    Tab,
    Col,
    Row,
} from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import {showLoadingModal, hideLoadingModal, getCurrentDate} from "../../logic/Utils";

import axios from "axios";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


const EnrolleeActivityGrid = (props) => {
    const gridRef = useRef(); // Grid reference
    const [rowData, setRowData] = useState(props.data); // Set initial row data
    const isSorted =props.sortField; // Set initial sort field
    const [searchText, setSearchText] = useState(""); // Store search text
    const gridStyle = useMemo(() => ({ height: "600px", width: "100%" }), []);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState(["fullName", "requestSource",
        "groupName", "totalWaitingTime"
    ]); // Default columns
    // Handle the row click event to show the modal
    const onRowClicked = (row) => {
        setSelectedActivity(row.data);
        setShowModal(true);
        setViewMode(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedActivity(null);
    };

    const activityColumns = [
        {headerName: "ID", field: "enrolleeId", flex: 1, hide: !visibleColumns.includes("enrolleeId")},
        {headerName: "Enrollee Name", field: "fullName", flex: 2, hide: !visibleColumns.includes("fullName")},
        {
            headerName: "Request Source",
            field: "requestSource",
            flex: 2,
            hide: !visibleColumns.includes("requestSource")
        },
        {
            headerName: "Group Name/Client",
            field: "groupName",
            flex: 2,
            hide: !visibleColumns.includes("groupName")
        },
        {
            headerName: "T1",
            field: "firstWaitingTime",
            flex: 1,
            hide: !visibleColumns.includes("firstWaitingTime"),
            cellStyle: (params) => {
                const value = params.value;
                return {
                    backgroundColor:
                        value > 60 ? "red" :
                            value > 30 ? "orange" : "green",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px"
                };
            },
        },
        {
            headerName: "T2",
            field: "secondWaitingTime",
            flex: 1,
            hide: !visibleColumns.includes("secondWaitingTime"),
            cellStyle: (params) => {
                const value = params.value;
                return {
                    backgroundColor:
                        value > 10 ? "red" :
                            value > 5 ? "orange" : "green",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px"
                };
            },
        },
        {
            headerName: "Total WT",
            field: "totalWaitingTime",
            flex: 1,
            sort: isSorted ? "desc" : "no",
            hide: !visibleColumns.includes("totalWaitingTime"),
            cellStyle: (params) => {
                const value = params.value;
                return {
                    backgroundColor: value > 75 ? "red" : "green",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px"
                };
            },
        },
        {
            headerName: "Date Present",
            field: "datePresentString",
            flex: 2,
            hide: !visibleColumns.includes("datePresentString")
        },
        {
            headerName: "Date of PA Request",
            field: "dateOfPaRequestString",
            flex: 2,
            hide: !visibleColumns.includes("dateOfPaRequestString")
        },
        {
            headerName: "Date of PA Issuance",
            field: "dateOfPaIssuanceString",
            flex: 2,
            hide: !visibleColumns.includes("dateOfPaIssuanceString")
        },
        {headerName: "Provider", field: "provider", flex: 3, hide: !visibleColumns.includes("provider")},
        {headerName: "Remarks", field: "remarks", flex: 3, hide: !visibleColumns.includes("remarks")},
    ];

    // Filter the columns to display based on user's selection
    const filteredColumns = activityColumns.filter(column =>
        visibleColumns.includes(column.field)
    );

    // Capture grid API when grid is ready
    const onGridReady = useCallback((params) => {
        gridRef.current.api = params.api; // Store the grid API in the gridRef
    }, []);

    // Column selection handler
    const handleColumnToggle = (column) => {
        setVisibleColumns(prev => prev.includes(column)
            ? prev.filter(c => c !== column)
            : [...prev, column]);
    };

    return (
        <React.Fragment>
            {/* Column Selection */}
            <div>
                <label>Select Columns: </label>
                {activityColumns.map(col => (
                    <label key={col.field} style={{marginRight: "10px"}}>
                        <input
                            type="checkbox"
                            checked={visibleColumns.includes(col.field)}
                            onChange={() => handleColumnToggle(col.field)}
                        />
                        {col.headerName}
                    </label>
                ))}
            </div>
            <div className="ag-theme-alpine" style={{height: 600, width: "100%"}}>
                <AgGridReact
                    ref={gridRef} // Setting the reference
                    rowData={props.data}
                    columnDefs={filteredColumns} // Filtered columns based on user selection
                    pagination={true}
                    paginationPageSize={20}
                    domLayout="autoHeight"
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        filter: true,
                        flex: 1,

                    }}
                    onRowClicked={onRowClicked} // Trigger modal on row click
                    onGridReady={onGridReady} // Set the grid API when ready



                />
            </div>


            {/* Modal for viewing selected activity */}
            {selectedActivity && viewMode && (
                <Modal size="xl" show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Enrollee Activity Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                {/* General Information - First Row */}
                                <Col lg={4} sm={12}>
                                    <Card style={{ marginBottom: "20px" }}>
                                        <Card.Header>
                                            <strong>General Information</strong>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedActivity.fullName || "N/A"}
                                                    disabled
                                                    style={{ marginBottom: "20px" }}
                                                />

                                                <Form.Label>Enrollee ID</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedActivity.enrolleeId || "N/A"}
                                                    disabled
                                                    style={{ marginBottom: "20px" }}
                                                />

                                                <Form.Label>Provider</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedActivity.provider || "N/A"}
                                                    disabled
                                                    style={{ marginBottom: "20px" }}
                                                />
                                                <Form.Label>Group Name/Client</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedActivity.groupName || "N/A"}
                                                    disabled
                                                    style={{ marginBottom: "20px" }}
                                                />


                                                <Form.Label>Request Source</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedActivity.requestSource || "N/A"}
                                                    disabled
                                                    style={{ marginBottom: "20px" }}
                                                />
                                                <Form.Label>PA Issued</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedActivity.paCodeIssued || "N/A"}
                                                    disabled
                                                    style={{ marginBottom: "20px" }}
                                                />

                                         </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </Col>
                             <Col lg={8} sm={12}>
                                 <Row>
                                     <Col lg={6} sm={12}>
                                         <Card style={{ marginBottom: "20px" }}>
                                             <Card.Header>
                                                 <strong>Time stamp Information</strong>
                                             </Card.Header>
                                             <Card.Body>
                                                 <Form.Group>

                                                     <Form.Label>Date Present</Form.Label>
                                                     <Form.Control
                                                         type="text"
                                                         value={selectedActivity.datePresentString || "N/A"}
                                                         disabled
                                                         style={{ marginBottom: "20px" }}
                                                     />
                                                     <Form.Label>Date of PA Request</Form.Label>
                                                     <Form.Control
                                                         type="text"
                                                         value={selectedActivity.dateOfPaRequestString || "N/A"}
                                                         disabled
                                                         style={{ marginBottom: "20px" }}
                                                     />
                                                     <Form.Label>Date of PA Issuance</Form.Label>
                                                     <Form.Control
                                                         type="text"
                                                         value={selectedActivity.dateOfPaIssuanceString || "N/A"}
                                                         disabled
                                                         style={{ marginBottom: "20px" }}
                                                     />
                                                 </Form.Group>

                                             </Card.Body>
                                         </Card>
                                     </Col>

                                     <Col lg={6} sm={12}>
                                         <Card style={{ marginBottom: "20px" }}>
                                             <Card.Header>
                                                 <strong>Waiting Time Information</strong>
                                             </Card.Header>

                                             <Card.Body>

                                                 <Form.Group>

                                                     <Form.Label>First Waiting Time (in minutes)</Form.Label>
                                                     <Form.Control
                                                         type="text"
                                                         value={selectedActivity.firstWaitingTime || "N/A"}
                                                         disabled
                                                         style={{ marginBottom: "20px" }}
                                                     />

                                                     <Form.Label>Second Waiting Time (in minutes)</Form.Label>
                                                     <Form.Control
                                                         type="text"
                                                         value={selectedActivity.secondWaitingTime || "N/A"}
                                                         disabled
                                                         style={{ marginBottom: "20px" }}
                                                     />

                                                     <Form.Label>Total Waiting Time (in minutes)</Form.Label>
                                                     <Form.Control
                                                         type="text"
                                                         value={selectedActivity.totalWaitingTime || "N/A"}
                                                         disabled
                                                         style={{ marginBottom: "20px" }}
                                                     />
                                                 </Form.Group>
                                             </Card.Body>
                                         </Card>
                                         {/*<Card style={{ marginBottom: "20px" }}>*/}
                                         {/*    <Card.Header>*/}
                                         {/*        <strong>Additional Information</strong>*/}
                                         {/*    </Card.Header>*/}
                                         {/*    <Card.Body>*/}
                                         {/*        <Form.Group>*/}
                                         {/*            <Form.Label>Remarks</Form.Label>*/}
                                         {/*            <Form.Control*/}
                                         {/*                as="textarea"*/}
                                         {/*                rows={3}*/}
                                         {/*                value={selectedActivity.remarks || "N/A"}*/}
                                         {/*                disabled*/}
                                         {/*                style={{ marginBottom: "20px" }}*/}
                                         {/*            />*/}
                                         {/*        </Form.Group>*/}
                                         {/*    </Card.Body>*/}
                                         {/*</Card>*/}
                                     </Col>
                                 </Row>
                                 <Row>
                                     <Row>

                                         <Col lg={12} sm={12}>
                                             {selectedActivity.requestSource === "Email PA Request" && (
                                             <Card style={{ marginBottom: "20px" }}>
                                                 <Card.Header>
                                                     <strong>Email Content(Applicable to Email PA Requests)</strong>

                                                         <Button
                                                             variant="danger"
                                                             size="sm"
                                                             style={{ marginLeft: "10px" }}
                                                             onClick={() => {/* Handle Report Error action for Date of PA Request */}}
                                                         >
                                                             Report Error
                                                         </Button>
                                                 </Card.Header>
                                                 <Card.Body>
                                                     <Form.Group>
                                                         <div
                                                             style={{
                                                                 border: "1px solid #ccc",
                                                                 padding: "10px",
                                                                 borderRadius: "4px",
                                                                 backgroundColor: "#f9f9f9",
                                                                 maxHeight: "300px",
                                                                 overflowY: "auto",
                                                             }}
                                                             dangerouslySetInnerHTML={{
                                                                 __html: selectedActivity.htmlContent || "<p>No email content available</p>",
                                                             }}
                                                         />
                                                     </Form.Group>
                                                 </Card.Body>
                                             </Card>
                                                )}
                                         </Col>
                                     </Row>
                                 </Row>
                             </Col>


                            </Row>


                            
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}


        </React.Fragment>
    );
};


export default function EnrolleeActivity() {
    const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
    const [skin, setSkin] = useState(currentSkin);
    const [isProcessed, setIsProcessed] = useState(false);
    const [activity, setActivity] = useState([]);
    const [startDate, setStartDate] = useState(getCurrentDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // Filtered data
    const [groupList, setGroupList] = useState([]);



    const handleMultiSelectChange = (selected) => {
        // Update the array of strings with selected values

        const selectedOptions = selected.map((item) => item.value);
        setSelectedOptions(selectedOptions);
        filterByGroupNames(activity, selectedOptions);
        console.log(selectedOptions);
    };

    function filterByGroupNames(data, groupNames) {
        // Check if data and groupNames are valid
        if (!Array.isArray(data) || !Array.isArray(groupNames)) {
            throw new Error("Both data and groupNames must be arrays");
        }

     const filtered =   data.filter(item =>
            item.groupName && groupNames.includes(item.groupName) // Ensure groupName exists and is in the list
        );
        // Filter the data
        console.log(filtered.length);
       setFilteredData(filtered);
    }

    const validateDates = () => {
        if (!startDate) {
            Swal.fire({
                title: "Validation Error",
                text: "Start date is required.",
                icon: "warning",
            });
            return false;
        }

        if (!endDate) {
            Swal.fire({
                title: "Validation Error",
                text: "End date is required.",
                icon: "warning",
            });
            return false;
        }

        if (new Date(startDate) > new Date(endDate)) {
            Swal.fire({
                title: "Validation Error",
                text: "Start date cannot be later than the end date.",
                icon: "warning",
            });
            return false;
        }

        return true;
    };

    useEffect(() => {
        Prism.highlightAll(); // Assumes Prism.js for syntax highlighting

        // Set up interval for fetching data every 5 minutes (300,000 milliseconds)
        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 5 minutes
        }, 300000); // 300000 milliseconds = 5 minutes

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [startDate, endDate]); // Re-run when startDate or endDate changes

    const fetchData = async () => {
        showLoadingModal();

        try {
            const response = await axios.get(
                "https://metroflowbackend-872093905250.us-central1.run.app/medicalservices/all",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                    params: {
                        startDate,
                        endDate,
                    },
                }
            );


            hideLoadingModal();

            if (response.data.result.length === 0) {
                Swal.fire({
                    title: "No Data Found",
                    text: "No enrollee activity found for the selected date range.",
                    icon: "info",
                });
            }
            else{
                const groupList = response.data.result.reduce((acc, item) => {
                    if (!acc.some(option => option.value === item.groupName)) {
                        acc.push({
                            value: item.groupName, // Use groupName for value
                            label: item.groupName  // Use groupName for label
                        });
                    }
                    return acc;
                }, []);
                setGroupList(groupList);

                setActivity(response.data.result);
                setIsProcessed(true);

            }
        } catch (error) {
            hideLoadingModal();
            Swal.fire({
                title: "Something went wrong!",
                text: error.message,
                icon: "error",
            });
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateDates()) return;

        fetchData();
    };

    useEffect(() => {
        Prism.highlightAll(); // Assumes Prism.js for syntax highlighting
    }, []);

    return (
        <React.Fragment>
            <Sidebar />
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <Container>
                    <label className="main-title-label">Waiting Time Tracking System</label>
                    <Card className="card-post mt-4"  style={{ marginBottom: "20px" }}>
                        <Card.Header>
                            <Card.Title>All Enrollee Activity</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm="6">
                                    <Form.Label htmlFor="startDate">Start Date</Form.Label>
                                    <Form.Control
                                        name="startDate"
                                        type="date"
                                        id="startDate"
                                        value={startDate}
                                        max={getCurrentDate()}
                                        className="form-control input-class"
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </Col>
                                <Col sm="6">
                                    <Form.Label htmlFor="endDate">End Date</Form.Label>
                                    <Form.Control
                                        name="endDate"
                                        type="date"
                                        id="endDate"
                                        value={endDate}
                                        className="form-control input-class"
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Card.Footer>
                    </Card>

                    <Tab.Container defaultActiveKey={"allActivityTab"} className="mt-5">
                        <Nav className="nav-line">
                            <Nav.Item>
                                <Nav.Link style={{ marginRight: "20px" }} eventKey="allActivityTab">
                                    All Enrollee Activity
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{ marginRight: "20px" }} eventKey="activityTabByWaitingTime">
                                    Enrollee Activity By Waiting Time
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{ marginRight: "20px" }} eventKey="activityTabPerGroup">
                                    Enrollee Activity By Client Group
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="allActivityTab">

                                <div>
                                    {isProcessed ? (
                                        <EnrolleeActivityGrid data={activity}
                                                              />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="activityTabByWaitingTime">
                                <div>
                                    {isProcessed ? (
                                        <EnrolleeActivityGrid data={activity}
                                                              sortField={true}/>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="activityTabPerGroup">

                                <div>
                                    {isProcessed ? (
<div>
                                            <Row>
                                                <Col sm="9">
                                                    <Form.Label htmlFor="multiSelect">Select Client / Group Name</Form.Label>
                                                    <Select
                                                        id="multiSelect"
                                                        isMulti
                                                        options={
                                                            groupList
                                                        }
                                                        onChange={handleMultiSelectChange}
                                                        placeholder="Select options..."
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                    />
                                                </Col>
                                            </Row>

                                        <EnrolleeActivityGrid data={filteredData}/>
    </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Container>
            </div>
        </React.Fragment>
    );
}
