import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Card,
  Container,
  Nav,
  Modal,
  Form,
  Row,
  Col,
  Button,
  Tab,
  Spinner,
} from "react-bootstrap";
import Footer from "../layouts/Footer";
import Swal from "sweetalert2";
import { showLoadingModal, hideLoadingModal } from "../logic/Utils";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import Select from 'react-select';
import axios from "axios";

import { Link } from "react-router-dom";
import MetroTariff from "../components/MetroTariff";
import TariffGrid from "../components/TariffGrid";

export default function TariffManager() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [providerData, setProviderData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");

  const [providerName, setProviderName] = React.useState("");
  const [reportData, setReportData] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [metroFile, setMetroFile] = React.useState(null);
  const [metrotariffData, setMetrotariffData] = React.useState([]);
  const [isMetroTariffsLoaded, setIsMetroTariffsLoaded] = React.useState(false);
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [showTariffModal, setShowTariffModal] = useState(false);
  const [providerTariffData, setProviderTariffData] = useState(null);
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      // console.log(selectedOption.providerName);
      setSelectedProvider(selectedOption);
    } else {
      setSelectedProvider(null); // Handle clearing the selection
    }
  };
  let userDetails = {};
  const userDetailsJSON = localStorage.getItem("userDetails");
  userDetails = JSON.parse(userDetailsJSON);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      showLoadingModal();
      try {
        const response = await axios.get(
          "https://metrotariff-872093905250.us-central1.run.app/tariff/providerTariff",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

       

        const providerOptions = response.data.result.map((item) => ({
          value: JSON.stringify(item), // This could be the id or any other identifier
          label: item.providerName,
          providerName: item.providerName,
          fileName: item.fileName,
        }));

        setProviderTariffData(providerOptions);
        hideLoadingModal();
      } catch (error) {
        hideLoadingModal();
        // Handle errors, e.g., show an error message
        console.error("Upload error:", error);
        Swal.fire({
          title: "Something went wrong!",
          text: error.message,
          icon: "error",
        });
      }
    };

    fetchData();
  }, []);
  const getMetroTariffs = async () => {
    setIsMetroTariffsLoaded(false);
    showLoadingModal();

    try {
      const response = await axios.get(
        "https://metrotariff-872093905250.us-central1.run.app/tariff/all",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      hideLoadingModal();
      console.log(response);
      const transformedData = response.data.result.map((item) => ({
        id: item.tariffId,
        tariffCode: item.tariffCode,
        tariffDescription: item.tariffDescription,
        category: item.category,
        isVariant: item.isVariant,
        originalDescription: item.originalDescription,
      }));

      setMetrotariffData(transformedData);
      setIsMetroTariffsLoaded(true);
    } catch (error) {
      hideLoadingModal();

      console.error("Upload error:", error);
    }
  };

  const handleClose = () => {
    setShowTariffModal(false);
    setSelectedTariff(null);
  };

  const handleUpload = async () => {
    setProviderData([]);
    setReportData(null);
    setIsProcessed(false);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("provider", providerName); // Replace with your actual provider value
    formData.append("file", file); // Replace with your actual file object

    try {
      const response = await axios.post(
        `https://metrotariff-872093905250.us-central1.run.app/tariff/${userDetails.userId}/provider/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const transformedData = response.data.result.providerTariffMatchList.map(
        (item) => ({
          providerTariffDescription: item.providerTariffDescription,
          matchedTariffDescription: item.matchedTariffDescription,
          suggestedTariffCode: item.suggestedTariffCode,
          suggestedTariffDescription: item.suggestedTariffDescription,
          matchingScore: item.matchingScore,
          category: item.category,
          tariffAmount:item.tariffAmount,
          id: item.providerTariffMatchId,
          providerName: item.providerName,
        })
      );
      setProviderData(transformedData);
      setReportData(response.data.result.tariffReport);
      setIsProcessed(true);

      Swal.fire({
        title: "Analysis Complete",
        text: "You can review the results, and match suggestions where necessary.",
        icon: "success",
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
    }
  };

  const handleAssignedUpload = async () => {
    setProviderData([]);
    setReportData(null);
    setIsProcessed(false);
    showLoadingModal();
if (!selectedProvider) {
        Swal.fire({
            title: 'Error',
            text: 'No provider selected. Please select a provider from the dropdown to continue.',
            icon: 'error',
        });
        return;
    }

    const name = userDetails.firstName;
    try {
      const response = await axios.post(
        `https://metrotariff-872093905250.us-central1.run.app/tariff/provider/${name}`,
        selectedProvider,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const transformedData = response.data.result.providerTariffMatchList.map(
        (item) => ({
          providerTariffDescription: item.providerTariffDescription,
          matchedTariffDescription: item.matchedTariffDescription,
          suggestedTariffCode: item.suggestedTariffCode,
          suggestedTariffDescription: item.suggestedTariffDescription,
          matchingScore: item.matchingScore,
          category: item.category,
          tariffAmount:item.tariffAmount,
          id: item.providerTariffMatchId,
          providerName: item.providerName,
        })
      );
      setProviderData(transformedData);
      setReportData(response.data.result.tariffReport);
      setIsProcessed(true);

      Swal.fire({
        title: "Analysis Complete",
        text: "You can review the results, and match suggestions where necessary.",
        icon: "success",
      });

      hideLoadingModal();
    } catch (error) {
      hideLoadingModal();
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
    }
  };
  const handleMetroUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", metroFile);

    try {
      const response = await axios.post(
        "https://metrotariff-872093905250.us-central1.run.app/tariff/upload/update",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsProcessed(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error("Upload error:", error);
    }
  };

  const handleAddTariff = (tariffDto) => {
    Swal.fire({
      title: "Unauthorized",
      text: "Admins Only",
      icon: "error",
    });

    return;
  };

  const createTariff = () => {
    setShowTariffModal(true);
  };
  const handleReportDownload = async () => {
    try {
      showLoadingModal();
  
      const response = await axios.get(
        "https://metrotariff-872093905250.us-central1.run.app/tariffReports/latest",
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Specify the responseType to 'blob'
        }
      );
  
      const filename = "Latest_Tariff_Reports.csv";
  
      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8;",
      });
  
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element and click on it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      // Release the temporary URL
      window.URL.revokeObjectURL(url);
      hideLoadingModal();
    } catch (error) {
      console.error("Download error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
      hideLoadingModal();
    }
  };
  
  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Container>
          <label className="main-title-label">TARIFF MANAGER</label>

          <Tab.Container defaultActiveKey={"uploadTab"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="uploadTab">
                  Upload Provider Tariff
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="TariffAnalysis"
                >
                  MetroHealth Tariffs
                </Nav.Link>
              </Nav.Item>
            </Nav>
            
            <Tab.Content>
              <Tab.Pane eventKey="uploadTab">
                {
                  <div>
                    <Row>
                      <Col md={6}>
                        <Card className="card-post mt-4">
                          <Card.Header>
                            <Card.Title>Upload Provider Tariff</Card.Title>
                            <Link to="" className="link-more">
                              <i className="ri-more-2-fill"></i>
                            </Link>
                          </Card.Header>
                          <Card.Body>
                            <div className="mb-3">
                              <Form.Label htmlFor="exampleFormControlInput1">
                                Name of Provider
                              </Form.Label>
                              <Form.Control
                                onChange={(event) =>
                                  setProviderName(event.target.value)
                                }
                                type="email"
                                id="exampleFormControlInput1"
                                placeholder=""
                              />
                              <br />
                              <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>
                                  Upload provider tariff sheet (Excel)
                                </Form.Label>
                                <Form.Control
                                  onChange={(event) =>
                                    setFile(event.target.files[0])
                                  }
                                  type="file"
                                />
                              </Form.Group>

                              {!isLoading && (
                                <Button
                                  variant="primary"
                                  onClick={handleUpload}
                                >
                                  Submit
                                </Button>
                              )}
                              {isLoading && (
                                <Button className="btn-sign">
                                  Processing...
                                  <span />
                                  <Spinner size="sm" animation="border" />
                                </Button>
                              )}
                            </div>

                            <br />
                          </Card.Body>
                          <Card.Footer></Card.Footer>
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card className="card-post mt-4">
                          <Card.Header>
                            <Card.Title>Select Provider from the List</Card.Title>
                            <Link to="" className="link-more">
                              <i className="ri-more-2-fill"></i>
                            </Link>
                          </Card.Header>
                          <Card.Body>
                            <div className="mb-3">
                       
                            <Select
    className="basic-multi-select"
    classNamePrefix="select"
    placeholder="Search here to Choose one"
    isClearable
    isSearchable
    name="provider"
    options={providerTariffData}
    onChange={handleSelectChange}
    value={selectedProvider ? { value: JSON.stringify(selectedProvider), label: selectedProvider.providerName } : null}
  />

                              <Button
                                variant="success"
                                style={{ marginTop: "20px" }}
                                onClick={handleAssignedUpload}
                              >
                                Get Hospital Tariff
                              </Button>

                              <Button
                                variant="primary"
                                style={{  marginTop: "20px",marginLeft: "20px" }}
                                onClick={handleReportDownload}
                              >
                                Get Provider Report 
                              </Button>
                            </div>
                          </Card.Body>
                          <Card.Footer></Card.Footer>
                        </Card>
                      </Col>
                    </Row>

                    {isProcessed && (
                      <TariffGrid
                        data={providerData}
                        reportData={reportData}
                        providerName={providerName}
                      />
                    )}
                  </div>
                }
              </Tab.Pane>

              <Tab.Pane eventKey="TariffAnalysis">
                {
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Upload MetroHealth MasterSheet </Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Upload tariff sheet (Excel)</Form.Label>
                          <Form.Control
                            onChange={(event) =>
                              setMetroFile(event.target.files[0])
                            }
                            type="file"
                          />
                        </Form.Group>

                        {!isLoading && (
                          <Button variant="primary" onClick={handleMetroUpload}>
                            Submit
                          </Button>
                        )}
                        {isLoading && (
                          <Button className="btn-sign">
                            Processing...
                            <span />
                            <Spinner size="sm" animation="border" />
                          </Button>
                        )}
                        <Button variant="secondary" onClick={createTariff}>
                          Create Tariff
                        </Button>
                        <Button variant="primary" onClick={getMetroTariffs}>
                          Get All Tariffs
                        </Button>
                      </div>

                      <br />

                      {isMetroTariffsLoaded && (
                        <MetroTariff data={metrotariffData} />
                      )}

                      {showTariffModal && (
                        <Modal show={showTariffModal} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Edit Tariff</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Label>Tariff Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Tariff Code"
                                value={
                                  selectedTariff
                                    ? selectedTariff.tariffCode
                                    : ""
                                }
                                onChange={(e) =>
                                  setSelectedTariff({
                                    ...selectedTariff,
                                    tariffCode: e.target.value,
                                  })
                                }
                                style={{ marginBottom: "20px" }}
                              />

                              <Form.Label>Tariff Description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Tariff Description"
                                value={
                                  selectedTariff
                                    ? selectedTariff.tariffDescription
                                    : ""
                                }
                                onChange={(e) =>
                                  setSelectedTariff({
                                    ...selectedTariff,
                                    tariffDescription: e.target.value,
                                  })
                                }
                                style={{ marginBottom: "20px" }}
                              />

                              <Form.Label>Tariff Category</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Tariff Category"
                                value={
                                  selectedTariff ? selectedTariff.category : ""
                                }
                                onChange={(e) =>
                                  setSelectedTariff({
                                    ...selectedTariff,
                                    category: e.target.value,
                                  })
                                }
                                style={{ marginBottom: "20px" }}
                              />

                              <Button
                                variant="primary"
                                onClick={() => handleAddTariff(selectedTariff)}
                              >
                                Submit
                              </Button>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                }
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </React.Fragment>
  );
}
