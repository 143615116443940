import axios from "axios";

const SEND_INVITE_TO_USER ="https://metroflowbackend-872093905250.us-central1.run.app/employee/send-invite";





class employeeService {

    sendInvite(dto, token) {
        return axios.post(SEND_INVITE_TO_USER ,dto, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    uploadKpiForAListOfUsers(formData, token) {
        return axios.post('https://metroflowbackend-872093905250.us-central1.run.app/employee/bulk/upload-kpi', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
          });
    }
    getAllEmployees(token){
        return axios.get("https://metroflowbackend-872093905250.us-central1.run.app/employee/all", {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getKpiFOrUser(token, userId) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/employee/${userId}/get-kpi`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getKpiFOrAUser(token) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/employee/get-kpi`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }
    getProfileFOrAUser(token) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/employee/profile`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getProfileFOrAUserById(token,id) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/employee/profile/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    // Implement other methods with similar changes...
}

export const EmployeeService = new employeeService();
