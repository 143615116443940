import axios from "axios";






class invoiceService {

 

getAllInvoices(token) {

    return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/invoices/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}


markInvoices(status, token,id) {
    return axios.post(`https://metroflowbackend-872093905250.us-central1.run.app/invoices/update/${id}?status=${status}` ,{}, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    });
}

}

export const InvoiceService = new invoiceService();
