import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Col, Row ,Card} from "react-bootstrap";
import axios from "axios";
import { showLoadingModal,hideLoadingModal } from "../logic/Utils";
import Swal from "sweetalert2";
import TariffMatchModal from "./TariffMatchModal";
const TariffGrid = (props) => {
  const providerName = props.providerName;
  const report = props.reportData;
  const [selectedProviderMatch, setSelectedProviderMatch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [aliasEntries, setAliasEntries] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  let userDetails = {};
  const userDetailsJSON = localStorage.getItem("userDetails");
  userDetails = JSON.parse(userDetailsJSON);

  const [rows, setRows] = useState(props.data);

  const handleView = (row) => {

   
 
     
     setShowModal(true);
      setViewMode(true);
      setSelectedProviderMatch(row);
    
   };
   const handleClose = () => {
    // Implement your edit logic here
    setShowModal(false);
    setSelectedProviderMatch(null);
  };


  const removeItemFromGrid = (item) => {
    const filteredRows = rows.filter((row) => row.id !== item.id);
    setRows(filteredRows);

      // Remove the item from the selection model
  const updatedSelectionModel = rowSelectionModel.filter(
    (selectedId) => selectedId !== item.id
  );
  setRowSelectionModel(updatedSelectionModel);
  };
  const columns = [
    {
      field: "providerTariffDescription",
      headerName: "Provider Tariff Description",
      width: 350,
    },
    {
      field: "suggestedTariffDescription",
      headerName: "Suggested Tariff Description",
      width: 500,
    },
    { field: "matchingScore", headerName: "Matching Score", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
        <Button
            variant="primary"
            style={{ marginRight: "10px" }}
            // startIcon={<EditIcon />}
            onClick={() => handleView(params.row)}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];


  const handleResolution = async () => {
    // Display loading modal while processing
    showLoadingModal();
  
    try {
      const name = userDetails.firstName;
      // Send POST request to add alias entries
      const response = await axios.post(
        `https://metrotariff-872093905250.us-central1.run.app/tariff/addAlias/${name}`,
        aliasEntries,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      // If the request is successful, hide loading modal and show success message
      if (response.data.message === "success") {
        hideLoadingModal();
         // Clear the checked items in the grid
         setRowSelectionModel([]);
           // Remove selected items from the grid
        const filteredRows = rows.filter(
          (row) => !rowSelectionModel.includes(row.id)
        );
        setRows(filteredRows);
        setRowSelectionModel([]);
        Swal.fire({
          title: "Suggestions Saved",
          text: " You can re-run the analysis to see the changes, or you can continue to review other results.",
          icon: "success",
        });
      }
    } catch (error) {
      // If an error occurs, log it and display error message
      console.error("Upload error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
     
    }
  };
  

  const handleExcelDownload = async () => {
    try {
      showLoadingModal();
  
      const response = await axios.post(
        "https://metrotariff-872093905250.us-central1.run.app/tariff/generate-report",
        rows,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Specify the responseType to 'blob'
        }
      );

      const filename = report.nameOfProvider + "_Tariff_Report.xlsx";

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and click on it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the temporary URL
      window.URL.revokeObjectURL(url);
      hideLoadingModal();
    } catch (error) {
      console.error("Download error:", error);
      Swal.fire({
        title: "Something went wrong!",
        text: error.message,
        icon: "error",
      });
    }
  };

  return (
    <React.Fragment>
      {" "}
      <Card className="mt-4">
      <Card.Body>
        <Card.Title>{report.nameOfProvider}</Card.Title>
        <Card.Text>
          <strong>Initial Completion Percentage:</strong> {report.initialCompletionPercentage}<br />
          <strong>Current Completion Percentage:</strong> {report.currentCompletionPercentage}<br />
          <strong>Last Updated:</strong> {report.dateUpdated}<br />
        </Card.Text>
      </Card.Body>
    </Card>
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        checkboxSelection
        onRowSelectionModelChange={(newSelectionModel) => {
          setRowSelectionModel(newSelectionModel);

          const selectedTariffs = newSelectionModel.map((selectedId) =>
            rows.find((row) => row.id === selectedId)
          );

          setAliasEntries(selectedTariffs);
        }}
        getRowHeight={() => 'auto'} 
        rowSelectionModel={rowSelectionModel}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'rgba(120,144,156,0.5)', // Custom header background color
            fontSize: '1rem', // Custom header font size
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(odd)': {
              backgroundColor: 'rgba(235,237,240,1)', // Lighter for odd rows
            },
            '&:nth-of-type(even)': {
              backgroundColor: 'rgba(215,217,220,1)', // Slightly darker for even rows
            },
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal', // Allow text wrapping
            wordWrap: 'break-word', // Break words if necessary
            lineHeight: '1.5', // Adjust line height for better readability
            alignItems: 'center', // Center the content vertically
            display: 'flex',
          },
        }}
      />
    </div>

      <Row style={{ marginTop: "20px" }} className="text-center mb-4">
        <Col>
          <Button  variant="primary" onClick={handleResolution}>
            Resolve Un-matched Tariffs
          </Button>
        </Col>
        <Col>
          {" "}
          <Button variant="primary" onClick={handleExcelDownload}>
            Download Tariff Sheet
          </Button>
        </Col>
      </Row>
      {(selectedProviderMatch && viewMode)&& (
        <div>
          {showModal && (
            <TariffMatchModal tariffMatch={selectedProviderMatch} closeModal={handleClose} onSuccess={removeItemFromGrid} />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default TariffGrid;
