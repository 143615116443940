import axios from "axios";
const CREATE_A_PROJECT="https://metroflowbackend-872093905250.us-central1.run.app/project/create";
const GET_ALL_PROJECTS_OF_MANAGER="https://metroflowbackend-872093905250.us-central1.run.app/project/fetch/manager?status=NEW";
const GET_ALL_PROJECTS_OF_USER="https://metroflowbackend-872093905250.us-central1.run.app/project/fetch?status=NEW";
class projectService {
    createProject(taskData, token) {
        return axios.post(CREATE_A_PROJECT, taskData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }
    getAllProjectsOfManager(token) {


        return axios.get(GET_ALL_PROJECTS_OF_MANAGER, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getAllProjectsByUserAndStatus(token,status,userId) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/project/fetch/${userId}/?status=${status}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getAllProjectsByStatus(token,status) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/project/fetch?status=${status}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }




    getAllProjectsByUser(token,userId) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/project/fetch/${userId}/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getAllProjectsByAUser(token) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/project/fetch/all`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }


    deleteProjectAndTasks(token,projectId) {
        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/project/${projectId}/delete/`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    getInfo(token, projectId) {


        return axios.get(`https://metroflowbackend-872093905250.us-central1.run.app/project/${projectId}/info`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    // Implement other methods with similar changes...
}

export const ProjectService = new projectService();
