
import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import Swal from "sweetalert2";
import {
  Card,
  Container,
  Nav,
  Form,
  Button,
  Tab,
  Spinner,
} from "react-bootstrap";
import Footer from "../../layouts/Footer";

import { showLoadingModal,hideLoadingModal } from "../../logic/Utils";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";

import axios from "axios";


import { Link } from "react-router-dom";
import InvoiceGrid from "./components/InvoiceGrid";
import { InvoiceService } from "../../service/InvoiceService";



export default function InvoiceTracker() {

  const [invoices, setInvoices] = useState([]);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);

  const [file, setFile] = React.useState(null);


  
  useEffect(() => {
    Prism.highlightAll();
  }, []);


  useEffect(() => {


    // if (checkPermission(userDetails[0]) ){
      showLoadingModal();
      InvoiceService.getAllInvoices(
     localStorage.getItem("token"),
   )
     .then((res) => {
       const invoiceData = res.data.result;
      
       setInvoices(invoiceData);
       hideLoadingModal();
     
     })
     .catch((err) => {
       hideLoadingModal();
       console.log(err);
     });
    // }

  
   
  }, []);



    const handleUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
   
    formData.append("file", file); // Replace with your actual file object

    try {
      const response = await axios.post(
        "https://metroflowbackend-872093905250.us-central1.run.app/invoices/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );


  

      setIsProcessed(true);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle errors, e.g., show an error message
      console.error("Upload error:", error);
    }
  };


  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Container>
          <label className="main-title-label">INVOICE TRACKER</label>
          {/* <h2 className="main-title"></h2> */}

         

          <Tab.Container defaultActiveKey={"uploadTab"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="uploadTab">
                  Upload Invoice Document From Medicloud
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="invoiceTab">
                  Invoice Records
                </Nav.Link>
              </Nav.Item>
             

            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="uploadTab">
                {
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Upload Invoices</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                      
                        <Form.Group controlId="formFile" className="mb-3">
                         
                          <Form.Control
                            onChange={(event) => setFile(event.target.files[0])}
                            type="file"
                          />
                        </Form.Group>

                        {!isLoading && (
                          <Button variant="primary" onClick={handleUpload}>
                            Submit
                          </Button>
                        )}
                        {isLoading && (
                          <Button className="btn-sign">
                            Processing...
                            <span />
                            <Spinner size="sm" animation="border" />
                          </Button>
                        )}
                       
                      </div>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                }
              </Tab.Pane>
              <Tab.Pane eventKey="invoiceTab">
              {invoices.length > 0 && (
                        <InvoiceGrid data={invoices} />
                      )}
              </Tab.Pane>
           
            </Tab.Content>
       
          </Tab.Container>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
}
