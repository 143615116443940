import axios from "axios";


const GET_RECENT_NOTIFICATIONS = "https://metroflowbackend-872093905250.us-central1.run.app/notifications/";
const CLEAR_ALL_NOTIFICATIONS = "https://metroflowbackend-872093905250.us-central1.run.app/notifications/clear";


class notificationService {

    getAllNewNotifications(token) {

        return axios.get(GET_RECENT_NOTIFICATIONS, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

    }

    clearAllNotifications(token) {
        return axios.post(CLEAR_ALL_NOTIFICATIONS, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    // Implement other methods with similar changes...
}

export const NotificationService = new notificationService();
